import slugify from 'slugify'

// match with gatsby-node
export function slug(value: string): string {
  return slugify(value, {
    lower: true,
    //remove: /[—–]/g,
    strict: true,
  })
}
